const buttonProperties = {
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    borderRadius: '30px',
    width: '135px',
    height: '35px',
    backgroundColor: '#D32027',
    marginRight: '10px',
};

export default buttonProperties;
